/* You can add global styles to this file, and also import other style files */
@media print {
  .hidden-print {
    display: none !important;
  }

  .display-print {
    z-index: 9999;
  }

  .dx-drawer-wrapper {
    height: fit-content;
  }

  header,
  footer,
  .dx-drawer-panel-content,
  .dx-toast-wrapper {
    display: none !important;
  }
}

.data-grid-header {
  font-size: 28px;
  font-weight: 600;
}

// /* Style the scrollbar track */
// ::-webkit-scrollbar {
//   width: 12px; /* Change this value to make the scrollbar wider or narrower */
//   height: 12px; /* For horizontal scrollbar */
// }

// /* Style the scrollbar thumb */
// ::-webkit-scrollbar-thumb {
//   background-color: darkgrey;
//   border-radius: 10px;
//   border: 3px solid transparent; /* To add some spacing around the thumb */
//   background-clip: content-box; /* Ensures the background is clipped to the box */
// }

// /* Optional: Style the scrollbar track */
// ::-webkit-scrollbar-track {
//   background: lightgrey;
//   border-radius: 10px;
// }

.dx-datagrid {
  .dx-row.dx-data-row > td {
    padding: 7px;
  }
  .dx-datagrid-table .dx-header-row > td {
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
